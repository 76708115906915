import { afterNextRender, afterRender, AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GlossaryService, LibModule } from '@adapt/adapt-shared-component-lib';
import { AppModule } from './app.module';
import { ViewerPagesContentService } from './services/content/viewer-pages-content.service';
import { AdaptDataService } from './services/adapt-data.service';
import { map } from 'rxjs';
import { CommonModule, DecimalPipe } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, LibModule, AppModule],
  providers: [DecimalPipe],
  selector: 'adapt-viewer-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'adapt-viewer';


  public $breadcrumbContent = this.content.getViewerSharedContentListener().pipe(map(content => content.breadcrumb))

  constructor(private content: ViewerPagesContentService,  private glossary: GlossaryService, private data: AdaptDataService) {
    //this.content.loadContent();
    afterRender(() => {
      require('uswds');
    })
  }


}
