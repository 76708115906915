<div #comboBoxContainer class="usa-combo-box-container display-flex flex-column">
  <label class="combo-box-label usa-label margin-y-0" [ngStyle]="labelStyle" [for]="comboID"
    >{{ label }}<span class="text-emergency" *ngIf="required"> (Required)</span></label
  >
  <ng-content select="[hint]"></ng-content>


  <p *ngIf="readonly">  {{value}}</p>

  <div *ngIf="!readonly" #parent [attr.data-placeholder]="placeholder" [ngStyle]="comboBoxStyle" class="usa-combo-box margin-top-1">
    <select
      [id]="comboID"
      [compareWith]="compareFunc"
      [(ngModel)]="value"
      (ngModelChange)="onChange(value)"
      class="usa-select"
      [name]="comboID">
      <!-- <option value>{{label}}</option> -->
      <option
        #options
        *ngFor="let item of items; let isFirst = first"
        [selected]="isFirst"
        [ngValue]="itemAccessor ? item[itemAccessor] : item">
        {{ item[itemLabel] || item }} {{itemLocalizedLabel && item[itemLabel] !== item[itemLocalizedLabel]  ? '(' + item[itemLocalizedLabel] + ')' : ''}}
      </option>
    </select>
  </div>

  <div role="alert" aria-live="polite" class="usa-combo-box-errors">
    <ng-content select="[errors]"></ng-content>
  </div>
</div>
