import {
  Component,
  computed,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { randomUUID } from 'crypto';
import { GlossaryService } from '../../services/glossary.service';
import * as XLSX from 'xlsx';

import { chartExplainTemplateParse, LanguageCode } from '@adapt/types';
import { xlsx_delete_row } from '../../util';
import { PageContentText } from '@adapt-apps/adapt-admin/src/app/admin/models/admin-content-text.model';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

interface ChartDataItem {
  [key: string]: string | number;
}

@Component({
  selector: 'lib-adapt-data-rep-grouped',
  templateUrl: './data-rep-grouped.component.html',
  styleUrls: ['./data-rep-grouped.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DataRepGroupedComponent implements OnInit {
  @ViewChild('explainationRegion') explainationRegion!: ElementRef;
  @ViewChild('explanationSwitch') explanationSwitch!: ElementRef;
  @ViewChild('glossarySwitch') glossarySwitch!: ElementRef;
  @ViewChild('dataModal') dataModal!: ElementRef;
  @ViewChild('dataModalCloseBtn') dataModalCloseBtn!: ElementRef;
  @ViewChild('dataModalSwitch') dataModalSwitch!: ElementRef;
  @ViewChild('bars') barPanel!: ElementRef;
  @ViewChild('dataTable', { static: false }) dataTable!: ElementRef;

  @Output() dataModalStateChange = new EventEmitter<boolean>();

  @Input() total = 0;
  @Input() content?: PageContentText;

  @Input() suppressed = false;
  @Input() noData = false;
  @Input() lang = 'en';

   

  public noDataItemCount = 0;
  public noDataSummary = '';

  public data: any[] = [];
  @Input() raw: any;

  currentFilter = 'all';
  currentSection = 'all';
  currentFilterIdx = signal(0);

  public currentChartData = computed(() => this.raw.chart.data[this.currentFilterIdx()].value)

  quickFilters: Record<string, { filters: string[]; metadata: { sectionName: string } }> = {};

  @Input() rawDataType = 'normal';

  @Input() id = crypto.randomUUID();

  @Input() filtered = false;
  @Input() filterClass: 'filtered' | 'suppressed' = 'filtered';

  useH1 = false;
  @Input() headingLvl: 1 | 2 | 3 | 4 = 2;
  headingLvl2: 2 | 3 | 4 | 5 = (this.headingLvl + 1) as 2 | 3 | 4 | 5;
  headingLvl3: 3 | 4 | 5 | 6 = (this.headingLvl2 + 1) as 3 | 4 | 5 | 6;

  groupedData: any[] = [];

  @Input() header = 'Title';
  @Input() insight = 'Insight';
  plainLanguage = 'Plain Language';
  plainLanguageMaxCount = 5;
  showGlossary = false;
  showGlossaryBtn = false;
  glossaryIdsString = '';
  dataRepSettings = {
    showPlainLanguage: false,
    showGlossary: false,
  };

  description = '';

  private firstFocusableElement: HTMLElement | null = null;
  private lastFocusableElement: HTMLElement | null = null;
  private focusableElementsString =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable], li[tabindex="0"], li[tabindex="-1"], tr[tabindex="0"], tr[tabindex="-1"]';

  localization = 'en-US';

  constructor(private glossary: GlossaryService, @Inject(DecimalPipe) private decimalPipe: DecimalPipe) {
    const saved = JSON.parse(localStorage.getItem('adapt-data-rep-settings') || '{}');
    if (saved.showPlainLanguage || saved.showGlossary) this.dataRepSettings = saved;
  }

  mapHeadingLvl(lvl: 1 | 2 | 3 | 4 | 5 | 6) {
    switch (lvl) {
      case 1:
        return 'h1';
      case 2:
        return 'h2';
      case 3:
        return 'h3';
      case 4:
        return 'h4';
      case 5:
        return 'h5';
      case 6:
        return 'h6';
    }
  }

  saveSettingsLocally() {
    localStorage.setItem(
      'adapt-data-rep-settings',
      JSON.stringify(
        this.dataRepSettings ?? {
          showPlainLanguage: false,
          showGlossary: false,
        }
      )
    );
  }

  // This function is used to convert an array of objects into an array of label/value objects for the dmeo on 11/13/23
  mapToLabelValueArray(array: any[]) {
    return array.map((item) => {
      let label = '';
      let value = 0;
      const definition = 'Pipe in definition here';

      Object.keys(item).forEach((key) => {
        if (typeof item[key] === 'string') {
          label = item[key];
        } else if (typeof item[key] === 'number') {
          value = item[key];
        }
      });

      return { label, value, definition };
    });
  }

  public onGroupSelect(filter: any, idx: number) {
    const prevSection = this.currentSection;

    this.currentFilterIdx.set(idx);
    this.currentSection = filter.key;
    this.currentFilter = 'all';

    if (prevSection !== filter.key) {
      this.header = this.currentSection;
      this.processData(this.currentSection);
    }
  }

  noSort() {
    return 0;
  }

  processData(currentSection?: string) {
    const { groupBy, xAxisValue, yAxisValue } = this.raw.chart;

    const filterBy = Array.isArray(this.raw.chart['filterBy'])
      ? this.raw.chart['filterBy'][this.currentFilterIdx()]
      : this.raw.chart['filterBy'];

    // map the raw chart data into the format we need

    const dataIDMap = this.raw.chart.data.reduce(
      (accum: any, value: any) => Object.assign(accum, { [value.id]: value.value }),
      {}
    );

    this.currentSection = currentSection ?? this.raw.chart.data[this.currentFilterIdx()].id;

     if (!currentSection && this.currentChartData().length > 1)  this.header = this.raw.title ?? this.raw.name;

    // Group data for chart
    const sumValue = yAxisValue === groupBy ? xAxisValue : yAxisValue;
    // Massage data for chart view
    if (this.currentFilter === 'all') {
      // Determine which value to sum

      // Generate consolidated "all" view
      const consolidatedData = this.consolidateData(dataIDMap[this.currentSection], groupBy, sumValue);

      this.data = Object.values(consolidatedData);
    } else {
      this.data = dataIDMap[this.currentSection].filter((item: ChartDataItem) => item[filterBy] === this.currentFilter);
    }

    this.total =
      this.raw.chart.data[this.currentFilterIdx()].total || dataIDMap[this.currentSection].reduce((acc: any, item: any) => acc + item[yAxisValue], 0);

    this.generateInsight(sumValue);
    // If data is an array of objects, check if it has the optional definition property
    // if (this.data.length && this.data[0].definition)
    //   this.showGlossaryBtn = true;

    // Generate plain language summary
    this.generatePlainLanguage();

    // Find largest value
    const largestValue = dataIDMap[this.currentSection].reduce(
      (max: any, item: any) => Math.max(max, item[yAxisValue]),
      -Infinity
    );

    // Collect a list of the unique IDs for each definition for proper ARIA labeling
    const glossaryItemIds: string[] = [];

    // Calculate total

    // Calculate percentages and flex amount based on largest value
    // Flex amount is determined by dividing the item's value by the largest value
    // In the visual representation, the largest value fills the full width of the chart
    // effectively setting itself as "100%"
    this.data = this.data.map((item: any, index: any) => {
      //console.log(item)
      item.percentage = (item[yAxisValue] / this.total) * 100;
      item.largest = item[yAxisValue] === largestValue;
      item.flexAmount = item[yAxisValue] / largestValue;
      glossaryItemIds.push(this.id + 'series-item-definition-' + index);
      return item;
    });
    // Sort the array from largest to smallest
    this.data.sort((a: any, b: any) => b[yAxisValue] - a[yAxisValue]);
    this.glossaryIdsString = glossaryItemIds.join(' ');
    this.generatePlainLanguageForZeroTotalItems();

    this.suppressed = this.data.some((d: any) => d['suppressed']);
  }

  private getTotalForInsight(filteredSubTotal: any, filterBy: string, sumValue: string) {
    if (this.suppressed) {

      const value = this.raw.chart.data[this.currentFilterIdx()].value;
      const filtered = value.filter((val: any) => this.currentFilter === 'all' || val[filterBy] === this.currentFilter);
      let total = 0;

      const showSuppressed = filtered.every((item: any) => item.suppressed);


      if(filteredSubTotal?.sum){
        total = filteredSubTotal.sum;
      }else if(this.currentFilter === 'all'){
        total = value.filter((val: any) => val[sumValue] > 0 && !val.suppressed).reduce((acc: any, val: any) => acc + val[sumValue], 0);
      }else{
        total = filtered.reduce((acc: any, val: any) => acc + val[sumValue], 0);
      }

      return showSuppressed ? this.content?.actions?.['suppressed'] : this.decimalPipe.transform(total);
    }


    const total = this.raw.chart.data[this.currentFilterIdx()].total
      ? this.raw.chart.data[this.currentFilterIdx()].total
      : this.currentChartData()
          .filter((val: any) => this.currentFilter === 'all' || val[filterBy] === this.currentFilter)


    return this.decimalPipe.transform(total          .reduce((acc: any, item: any) => acc + item[sumValue], 0) || 0);
  }

  private getPercentageForInsight(filteredSubTotal: any, filterBy: string, sumValue: string) {
    if (this.suppressed) {

      const value = this.raw.chart.data[this.currentFilterIdx()].value
      
      const filtered = value.filter((val: any) => this.currentFilter === 'all' || val[filterBy] === this.currentFilter);

      const showSuppressed = filtered.every((item: any) => item.suppressed);


      let filteredTotal = 0;

      if(filteredSubTotal?.sum){
        filteredTotal = filteredSubTotal.sum;
      }else if(this.currentFilter === 'all'){
        filteredTotal = value.filter((val: any) => val[sumValue] > 0 && !val.suppressed).reduce((acc: any, val: any) => acc + val[sumValue], 0);
      }else{
        filteredTotal = value.filter((val: any) => val[filterBy] === this.currentFilter).reduce((acc: any, val: any) => acc + val[sumValue], 0);
      }


      const total = this.getTotal(sumValue);

      

      const percentage = (filteredTotal / total) * 100 || 0;

      return showSuppressed ? this.content?.actions?.['suppressed'] : total == 0 ? `0%` : `${percentage.toFixed(2)}%`;
    }

    const filteredTotal = !this.isNoData()
      ? this.currentChartData()
          .filter((val: any) => this.currentFilter === 'all' || val[filterBy] === this.currentFilter)
          .reduce((acc: any, item: any) => acc + item[sumValue], 0)
      : this.raw.chart.data[this.currentFilterIdx()].total;

    const total = this.getTotal(sumValue);

    const percentage = (filteredTotal / total) * 100 || 0;

    return total == 0 ? `0%` : `${percentage.toFixed(2)}%`;
  }

  private getTotal(sumValue: string) {
    return this.raw.chart.data[this.currentFilterIdx()].total > 0
      ? this.raw.chart.data[this.currentFilterIdx()].total
      : this.currentChartData().reduce((acc: any, item: any) => acc + item[sumValue], 0);
  }

  private async generateInsight(sumValue: any) {
    const filterBy = Array.isArray(this.raw.chart['filterBy'])
      ? this.raw.chart['filterBy'][this.currentFilterIdx()]
      : this.raw.chart['filterBy'];

    const filteredSubTotal = this.raw.chart.data[this.currentFilterIdx()]?.sub_totals?.find((st: string) => {
      return st[filterBy] === this.currentFilter;
    });

    //console.log(this.raw.chart.data[this.currentFilterIdx()], filterBy, this.currentFilter)

    const parseRegex = /{{(.+?)}}/g;

    if (this.raw.descriptionTemplate)
      return (this.insight = this.raw.descriptionTemplate.replaceAll(parseRegex, (match: string, code: string) => {
        if (code === 'total') {
          return this.getTotalForInsight(filteredSubTotal, filterBy, sumValue);
        } else if (code === 'percentage') {
          return this.getPercentageForInsight(filteredSubTotal, filterBy, sumValue);
        } else if (code === 'filter') {
          return this.currentFilter === 'all' ? this.raw.allMap : `${this.raw.prefix || ''} ${this.currentFilter}`;
        }

        return '';
      }));

    return (this.insight = this.raw.subtitle ?? this.raw.description);
  }

  generatePlainLanguage() {
    // Slice the array to include only the top items as per plainLanguageMaxCount
    const topItems = this.data.slice(0, this.plainLanguageMaxCount);

    const consolidatedData = this.consolidateData(topItems, this.raw.chart.xAxisValue, this.raw.chart.yAxisValue);

    const items: any[] = Object.values(consolidatedData);

    // Convert each item into a plain language string
    const plainLanguageItems = items.flatMap((item: any) => {
      // Convert the value to a percentage string with two decimal places
      const percentageResult =
        (item[this.raw.chart.yAxisValue] /
          items.reduce((acc: any, cur: { [x: string]: any }) => acc + cur[this.raw.chart.yAxisValue], 0)) *
        100;
      const percentage = isNaN(percentageResult) ? '0.00' : percentageResult.toFixed(2);

      // Format the string with the label and the percentage
      return `${this.glossary.getTermSafe(item[this.raw.chart.xAxisValue]).label} (${percentage}%)`;
    });

    const explainTemplate = this.raw?.explainTemplate as string;

    this.plainLanguage = chartExplainTemplateParse(explainTemplate, plainLanguageItems);
  }

  private consolidateData(data: any[], groupBy: string, sumValue: string) {
    return data.reduce((acc: any, item: any) => {
      const key = item[groupBy];

      if (!acc[key]) {
        acc[key] = { ...item, [groupBy]: key, [sumValue]: 0 };
      }
      acc[key][sumValue] += item[sumValue];
      // console.log(acc);
      return acc;
    }, {} as Record<string, any>);
  }

  togglePlainLanguage() {
    this.dataRepSettings.showPlainLanguage = !this.dataRepSettings.showPlainLanguage;
    this.saveSettingsLocally();
    this.setupTabbing();
    // this.explanationSwitch.nativeElement.setAttribute('aria-pressed', this.dataRepSettings.showPlainLanguage);
    // this.explainationRegion.nativeElement.setAttribute('aria-expanded', this.dataRepSettings.showPlainLanguage);
  }

  setupTabbing() {
    if (this.dataRepSettings.showPlainLanguage) {
      this.explainationRegion?.nativeElement.addEventListener('keydown', this.handleTabFromPanel);
      this.explanationSwitch?.nativeElement.addEventListener('keydown', this.handleTabFromPlainLanguageBtn);
      this.glossarySwitch?.nativeElement.addEventListener('keydown', this.handleTabFromGlossaryBtn);
    } else {
      this.explanationSwitch?.nativeElement.focus();
      this.explainationRegion?.nativeElement.removeEventListener('keydown', this.handleTabFromPanel);
      this.explanationSwitch?.nativeElement.removeEventListener('keydown', this.handleTabFromPlainLanguageBtn);
      this.glossarySwitch?.nativeElement.removeEventListener('keydown', this.handleTabFromGlossaryBtn);
    }
  }

  handleTabFromPanel = (event: KeyboardEvent) => {
    // Handle forward tab (Tab without Shift)
    if (event.key === 'Tab' && !event.shiftKey) {
      event.preventDefault();
      this.glossarySwitch.nativeElement.focus();
    }
    // Handle backward tab (Shift + Tab)
    else if (event.key === 'Tab' && event.shiftKey) {
      event.preventDefault();
      this.explanationSwitch.nativeElement.focus();
    }
  };

  handleTabFromPlainLanguageBtn = (event: KeyboardEvent) => {
    // Handle forward tab (Tab without Shift)
    if (event.key === 'Tab' && !event.shiftKey) {
      event.preventDefault();
      this.explainationRegion.nativeElement.focus();
    }
  };

  handleTabFromGlossaryBtn = (event: KeyboardEvent) => {
    // Handle backward tab (Shift + Tab)
    if (event.key === 'Tab' && event.shiftKey) {
      event.preventDefault();
      this.explainationRegion.nativeElement.focus();
    }
  };

  toggleGlossary() {
    this.dataRepSettings.showGlossary = !this.dataRepSettings.showGlossary;
    this.saveSettingsLocally();
  }

  openDataModal() {
    this.dataModal.nativeElement.hidden = false;
    // const focusableElements = this.dataModal.nativeElement.querySelectorAll(
    //   this.focusableElementsString
    // ) as NodeListOf<HTMLElement>;
    // this.firstFocusableElement = focusableElements[0];
    // this.lastFocusableElement = focusableElements[focusableElements.length - 1];

    this.dataModalCloseBtn.nativeElement.focus();
    this.dataModal.nativeElement.addEventListener('keydown', this.trapTabKey);
    this.dataModalStateChange.emit(true);
  }

  trapTabKey = (event: KeyboardEvent) => {
    // const deepActiveElement = document.activeElement;

    if (event.key === 'Tab') {
      // if (event.shiftKey) {
      //   /* shift + tab */
      //   if (deepActiveElement === this.firstFocusableElement) {
      //     event.preventDefault();
      //     this.lastFocusableElement!.focus();
      //   }
      // } else {
      //   /* tab */
      //   if (deepActiveElement === this.lastFocusableElement) {
      //     event.preventDefault();
      //     this.firstFocusableElement!.focus();
      //   }
      // }
    } else if (event.key === 'Escape') {
      this.closeModal();
    }
  };

  closeModal() {
    this.dataModal.nativeElement.hidden = true;
    this.dataModal.nativeElement.removeEventListener('keydown', this.trapTabKey);
    this.dataModalSwitch.nativeElement.focus(); // Return focus to the element that opened the modal
    this.dataModalStateChange.emit(false);
  }

  applyQuickFilter(filter: string) {
    this.currentFilter = filter;
    if (filter !== 'all') this.header = filter;
    else this.header = this.raw.title ?? this.raw.name;
    this.processData(this.currentSection);
  }

  ngOnInit(): void {
    // if (this.raw) {

    //   // this.data = this.raw.chart.data;

    //   // sort by letters first (i.e "Birth to 1" before "1 to 2")

    //   // if (this.rawDataType === 'barChart')
    //   //   this.data = this.mapToLabelValueArray(this.raw.chart.data);
    //   // else this.data = this.mapToLabelValueArray(this.raw.data);
    // }

    this.processData();

    const sortFilters = (data: any, idx: any) => {
      const newFilters = Array.from(
        new Set(
          data.map(
            (item: ChartDataItem) =>
              item[Array.isArray(this.raw.chart.filterBy) ? this.raw.chart.filterBy[idx] : this.raw.chart.filterBy]
          )
        ),
        (value) => String(value)
      ).sort(
        (a, b) =>
          Number(/^[0-9]/.test(a)) - Number(/^[0-9]/.test(b)) || a.localeCompare(b, undefined, { numeric: true })
      );

      // newFilters.unshift('all');

      return newFilters;
    };


    this.quickFilters = this.raw.chart.data.reduce(
      (accum: any, val: any, idx: number) =>
        Object.assign(accum, { [val.id]: { filters: sortFilters(val.value, idx), metadata: val.metadata  } }),
      {}
    );
  }

  ngAfterViewInit(): void {
    this.setupTabbing();
  }

  generatePlainLanguageForZeroTotalItems() {
    this.noDataSummary = ''; // reset message
    // Build a plain language sentence detailing which items have no data to show when suppression is off, but items still have no data
    // Get items with no data
    const noDataItems = this.data.filter((item) => item[this.raw.chart.yAxisValue] <= 0 && !item['suppressed']);
    this.noDataItemCount = noDataItems.length;
    // Get the plain language label for each item
    const plainLanguageItems = noDataItems.map(
      (item) => this.glossary.getTermSafe(item[this.raw.chart.groupBy], undefined, this.lang as LanguageCode).label
    );
    if (plainLanguageItems.length > 2) {
      // Join all items with commas, but the last item with 'and'
      const allButLast = plainLanguageItems.slice(0, -1).join(', ');
      const lastItem = plainLanguageItems[plainLanguageItems.length - 1];
      this.noDataSummary += `${allButLast}, and ${lastItem}`;
    } else if (plainLanguageItems.length === 2) {
      // No comma, just 'and'
      this.noDataSummary += `${plainLanguageItems[0]} or ${plainLanguageItems[1]}`;
    } else if (plainLanguageItems.length === 1) {
      // If there's only one item, just add it
      this.noDataSummary += `${plainLanguageItems[0]}`;
    }
    this.noDataSummary += '.';
  }

  public downloadData(what: 'csv' | 'xlsx') {
    const fileName = `${this.header}.${what}`;
    const workbook = XLSX.utils.table_to_book(this.dataTable.nativeElement);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const range = XLSX.utils.decode_range(worksheet['!ref']!);

    xlsx_delete_row(worksheet, range.e.r);

    XLSX.writeFile(workbook, fileName, { bookType: what });
  }

  public isNoData() {
    // check suppressed values that are not zero
    if (
      this.currentFilter !== 'all' &&
      this.raw.chart.subTotals?.find(
        (total: { [x: string]: string }) =>
          total[
            Array.isArray(this.raw.chart.filterBy)
              ? this.raw.chart.filterBy[this.currentFilterIdx()]
              : this.raw.chart.filterBy
          ] === this.currentFilter
      )
    )
      return false;
    // show no data no matter suppression
    if (this.data.reduce((acc, item) => acc + item[this.raw.chart.yAxisValue], 0) === 0) return true;
    return this.suppressed
      ? this.raw.chart.data[this.currentFilterIdx()].total === 0
      : this.raw.chart.data?.length <= 0 || this.data.every((item) => item[this.raw.chart.yAxisValue] <= 0);
    //return (this.raw.chart.data?.length <= 0 || this.data.every((item) => item[this.raw.chart.yAxisValue] <= 0));
  }

  public get filterOrSuppress() {
    const filtered = this.filtered || this.currentFilter !== 'all';
    if (filtered && this.suppressed) {
      return `(${this.content?.actions?.['suppressed']}, ${this.content?.actions?.['filtered']})`;
    } else if (filtered) {
      return `(${this.content?.actions?.['filtered']})`;
    } else if (this.suppressed) {
      return `(${this.content?.actions?.['suppressed']})`;
    }
    return '';
  }
}
