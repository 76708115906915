import { Component, OnInit } from '@angular/core';

import { ViewerContentText, HomePageContentText, QuestionContentText } from '../../models/content-text.model';
import { ViewerPagesContentService } from '../../services/content/viewer-pages-content.service';
import { AdaptDataService } from '../../services/adapt-data.service';
import { map, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@adapt/adapt-shared-component-lib';

@Component({
  selector: 'adapt-viewer-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  contentText: ViewerContentText | null;
  homePageText: HomePageContentText | null;
  selFreqAskedQuestions: QuestionContentText[] = [];

  public reports$ = this.language.$language.pipe(
    switchMap((language) => this.data.getReports(language).pipe(map((reports) => reports.slice(0, 5))))
  );

  constructor(
    public viewerPagesContentService: ViewerPagesContentService,
    private data: AdaptDataService,
    private route: ActivatedRoute,
    private language: LanguageService,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      if ('slug' in params) {
        this.data.loadSharedReport(params['slug'] as string).subscribe((result) => {
          this.router.navigate(['reports', result.reportSlug], {
            state: { filters: result.filters, page: result.tabIndex },
          });
        });
      }
    });
  }

  ngOnInit() {
    this.viewerPagesContentService.getViewerContentListener().subscribe((viewerContentText) => {
      //console.log('viewer contentText: ', viewerContentText);

      if (viewerContentText) {
        this.contentText = viewerContentText;

        this.homePageText = this.viewerPagesContentService.getPageContent('home') as HomePageContentText;

        //console.log('homePageText: ', this.homePageText);

        if (viewerContentText?.shared) {
          this.selFreqAskedQuestions = [];
          // now get the selected frequently asked questions
          this.contentText?.shared?.frequentlyAskedQuestions.categories.forEach((c) => {
            const qList = c.questions.filter((q) => q.addToLanding === true);

            qList.forEach((q) => {
              q.categoryName = c.name;
            });

            //console.log('qList: ', qList);
            this.selFreqAskedQuestions = [...this.selFreqAskedQuestions, ...qList];
          });

          //console.log('selFreqAskedQuestions: ', this.selFreqAskedQuestions);
        }
      }
    });
  }
}
