import { Component } from '@angular/core';
import { ViewerPagesContentService } from '../../services/content/viewer-pages-content.service';
import { map } from 'rxjs';
// import { SettingsService } from '@adapt/adapt-shared-component-lib';

@Component({
  selector: 'adapt-viewer-footer',
  templateUrl: './viewer-footer.component.html',
  styleUrls: ['./viewer-footer.component.scss'],
})
export class ViewerFooterComponent {
  public year = new Date().getFullYear();

  public $content = this.content.getViewerSharedContentListener().pipe(map(shared  => shared.footer));

  constructor(private content: ViewerPagesContentService) {}
}
