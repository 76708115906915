import { SettingsService } from '@adapt/adapt-shared-component-lib';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LanguageModel } from '@pulumi/aws/transcribe';
import { BehaviorSubject } from 'rxjs';
import {WeissAccessibilityCenterService} from 'weiss-accessibility-center'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private language = new BehaviorSubject('en'); 

  public $language = this.language.asObservable();

  constructor(private settings: SettingsService, @Inject(DOCUMENT) private document: Document, private weiss: WeissAccessibilityCenterService) { 
    this.settings.getSettingsObservable().subscribe(settings => {
      if (settings.defaultLanguage && !localStorage.getItem('lang')) {
       return this.language.next(settings.defaultLanguage);
      }
      this.language.next(localStorage.getItem('lang') || 'en')
    });
  }

  public changeLanguage(lang: string){
    this.language.next(lang);
    this.document.documentElement.lang = lang;
    this.weiss.updateSettings({language: lang})
  }

  public getLang(){
    return this.language.getValue();
  }

  public reset(){
   this.changeLanguage('en')
  }

}
